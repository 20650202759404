import axios from "axios";
import { logoutApi, pagereload } from "../constant/constant";

export const getFaqs = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/faqs`
    );
    if (res) {
      const data = res.data.data;
      return data;
    }
  } catch (err) {
    throw new Error("Something went wrong!!!");
  }
};
export const ValidatePledgeForm = async (data) => {
  try {
    const response = await axios.post(
      `https://www.carepalsecure.com/backend/api/v1/protection-pledge`,
      data,
    );
    if (response) {
      return response;
    }
  } catch (err) {
    throw new Error("Something went wrong!!!");
  }
};

export const planeSelectd = async (planeData) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/create-order`,
      planeData
    );
    if (res) {
      return res;
    }
  } catch (err) {
    throw new Error("Something went wrong!!!");
  }
};

export const successdata = async (orderId) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/order-details`,
      orderId
    );
    if (res) {
      return res;
    }
  } catch (err) {
    throw new Error("Something went wrong!!!");
  }
};
export const generateLead = async (payload) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/generate-lead`,
      payload
    );
    if (res) {
      return res;
    }
  } catch (err) {
    throw new Error("Something went wrong!!!");
  }
};

export const getQuestionsList = async (text, orderBy, page) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/community/forum-search`,
      {
        params: {
          text,
          order_by: orderBy,
          page,
        },
      }
    );
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const getCategories = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/community/category-list`
    );
    return res;
  } catch (err) {
    if (err.response.status === 401) throw new Error("Login Again!!!");
    throw new Error("Something went wrong!!!");
  }
};

export const getQuestionsListByCategory = async (
  text,
  categoryId,
  orderBy,
  page
) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/community/forum-search`,
      {
        params: {
          text,
          category_id: categoryId,
          order_by: orderBy,
          page,
        },
      }
    );
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const getTrendingQuestions = async (type, categoryId) => {
  try {
    const res = await axios.get(
      categoryId
        ? `${process.env.REACT_APP_BACKEND_APP_URL}api/v1/community/forum-search?${type}=1&category_id=${categoryId}`
        : `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/community/forum-search?${type}=1`
    );
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const getForum = async (id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/community/forum/get?forum_id=${id}`
    );
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const getAnswers = async (id) => {
  //
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/community/forum/get?parent_id=${id}&per_page=50`
    );
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const giveResponse = async (data, token) => {
  try {
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/community/engagement-response`,
      data,
      headers
    );
    return res;
  } catch (err) {
    if (err.response.status === 401) {
      logoutApi(token);
      pagereload();
      throw new Error("Login Again!!!");
    } else {
      throw new Error("Something went wrong!!!");
    }
  }
};

export const giveComment = async (data, token) => {
  ///
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/community/comments`,
      data,
      headers
    );
    return res;
  } catch (err) {
    if (err.response.status === 401) {
      logoutApi(token);
      pagereload();
      throw new Error("Login Again!!!");
    } else {
      throw new Error("Something went wrong!!!");
    }
  }
};

export const getTags = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/community/tag-list`
    );
    return res;
  } catch (err) {
    if (err.response.status === 401) throw new Error("Login Again!!!");
    throw new Error("Something went wrong!!!");
  }
};

export const createForum = async (data, token) => {
  try {
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/community/createForum`,
      data,
      headers
    );
    return res;
  } catch (err) {
    if (err.response.status === 401) {
      logoutApi(token);
      pagereload();
      throw new Error("Login Again!!!");
    } else {
      throw new Error("Something went wrong!!!");
    }
  }
};

export const UploadFile = async (data, token) => {
  try {
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/policy-analyzer/upload-policy`,
      data,
      headers
    );
    if (res) {
      return res;
    }
  } catch (err) {
    if (err.response.status === 401) {
      logoutApi(token);
      pagereload();
      throw new Error("Login Again!!!");
    } else {
      throw new Error("Something went wrong!!!");
    }
  }
};

export const getPolcy = async (id, token) => {
  //
  try {
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/policy-analyzer/getAllPolicies/${id}`,
      headers
    );
    return res;
  } catch (err) {
    if (err.response.status === 401) {
      logoutApi(token);
      pagereload();
      throw new Error("Login Again!!!");
    } else {
      throw new Error("Something went wrong!!!");
    }
  }
};

export const getPolcyDetails = async (id, token) => {
  //
  try {
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/policy-analyzer/getOnePolicy/${id}`,
      headers
    );
    return res;
  } catch (err) {
    if (err.response.status === 401) {
      logoutApi(token);
      pagereload();
      throw new Error("Login Again!!!");
    } else {
      throw new Error("Something went wrong!!!");
    }
  }
};

export const getProfileapi = async (token) => {
  //
  try {
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/getProfile`,
      headers
    );
    return res;
  } catch (err) {
    if (err.response.status === 401) {
      logoutApi(token);
      pagereload();
      throw new Error("Login Again!!!");
    } else {
      throw new Error("Something went wrong!!!");
    }
  }
};

export const editeProfile = async (formdata, token) => {
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/editUserProfile`,
      formdata,
      headers
    );
    return res;
  } catch (err) {
    if (err.response.status === 401) {
      logoutApi(token);
      pagereload();
      throw new Error("Login Again!!!");
    } else {
      throw new Error("Something went wrong!!!");
    }
  }
};

export const downloadPolicypdf = async (id, token) => {
  //
  try {
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/policy-analyzer/downloadPolicy/${id}`,
      headers
    );
    return res;
  } catch (err) {
    if (err.response.status === 401) {
      logoutApi(token);
      pagereload();
      throw new Error("Login Again!!!");
    } else {
      throw new Error("Something went wrong!!!");
    }
  }
};

export const GoogleLogin = async (data, token) => {
  try {
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/auth/callback`,
      data,
      headers
    );
    if (res) {
      return res;
    }
  } catch (err) {
    if (err.response.status === 401) {
      logoutApi(token);
      pagereload();

      throw new Error("Login Again!!!");
    } else {
      throw new Error("Something went wrong!!!");
    }
  }
};

export const GoogleLogout = async (token) => {
  try {
    const headers = { headers: { Authorization: `Bearer ${token}` } };

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/logout`,
      {},
      headers
    );
    if (res) {
      return res;
    }
  } catch (err) {
    throw new Error("Something went wrong!!!");
  }
};

export const getuserDetails = async (planeData) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/get-user-details`,
      planeData
    );
    if (res) {
      return res;
    }
  } catch (err) {
    throw new Error("Something went wrong!!!");
  }
};