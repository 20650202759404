// import React, { useContext, useState, useEffect } from "react";
// import axios from "axios";
// import Modal from "react-bootstrap/Modal";
// import GoogleLogin from "react-google-login";
// import {
//   setProfileImg,
//   setToken as setLocalStorageToken,
// } from "../../Services/constant/constant";
// import MyAlert from "../../Utils/MyAlert";
// import AuthContext from "../AuthContext";
// import styles from "./styles.module.scss";
// import axiosInstance from "../AxiosConfig";
// import { gapi, loadAuth2 } from "gapi-script";
// import pic from "../../assets/images/google.png";

// const LoginModal = function ({ loginOpen, setLoginOpen }) {
//   const { setToken } = useContext(AuthContext);

//   const [notify, setNotify] = useState({
//     isOpen: false,
//     message: "",
//     type: "",
//   });

//   useEffect(() => {
//     // function start() {
//     //   console.log(gapi, "asfasf");
//     //   gapi.client &&
//     //     gapi.client.init({
//     //       clientId:
//     //         "1092744361361-2nvdccigegb2r0u5q5hrds54o1f5nui1.apps.googleusercontent.com", //DEV
//     //       // clientId: '224761792682-qtk0ag2nofb0dcm8nkack3gktvb1ej4e.apps.googleusercontent.com',
//     //       scope: "email",
//     //     });
//     // }
//     // gapi.load("client", start);

//     // function init() {
//     //   gapi.load("client", () => {
//     //     gapi.client &&
//     //       gapi.client.init({
//     //         // clientId: `${process.env.REACT_APP_GOOGLE_LOGIN_CLIENTID}`,
//     //         clientId:
//     //           "1092744361361-2nvdccigegb2r0u5q5hrds54o1f5nui1.apps.googleusercontent.com",
//     //         scope: "email",
//     //       });
//     //   });
//     // }

//     // setTimeout(init, 2000);
//     // init();

//     // new code

//     async function init() {
//       const auth2 = await loadAuth2(
//         gapi,
//         process.env.REACT_APP_GOOGLE_LOGIN_CLIENTID,
//         ""
//       );

//       if (!auth2.isSignedIn.get()) {
//         gapi.load("client", () => {
//           gapi.client &&
//             gapi.client.init({
//               clientId: `${process.env.REACT_APP_GOOGLE_LOGIN_CLIENTID}`,
//               scope: "email",
//             });
//         });
//       } else {
//         let googleData = {
//           accessToken: auth2.currentUser.get().xc.access_token,
//         };
//         callBack(googleData);
//       }
//     }
//     init().catch((e) => console.log(e));

//   }, []);

//   const responseGoogle = async (response) => {

//     setNotify({
//           isOpen: true,
//           message: response.details,
//           type: "error",
//         })
//         // if(response.error){
//         //   setLoginOpen(false)
//         // }

//   };

//   const onGoogleLogin = async (googleData) => {
//     callBack(googleData);
//     // await axios
//     // .post("/api/v1/auth/callback", {
//     //   provider: "google",
//     //   token: googleData.accessToken,
//     // })
//     // .then((res) => {
//     //   if (res.data.error) {
//     //     if (res.data.status === false)
//     //       throw new Error("Email or password is incorrect");
//     //   }

//     //   setToken(res.data.data.token);
//     //   setLocalStorageToken(res.data.data.token);
//     //   setProfileImg(res.data.data.user.profile_photo);
//     //   setLoginOpen(false);

//     // })
//     // .catch((e) => {
//     //   setNotify({
//     //     isOpen: true,
//     //     message: e.message,
//     //     type: "error",
//     //   });
//     // });
//   };

//   async function callBack(googleData) {
//     await axios
//       .post(`${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/auth/callback`, {
//         provider: "google",
//         token: googleData.accessToken,
//       })
//       .then((res) => {
//         if (res.data.error) {
//           if (res.data.status === false)
//             throw new Error("Email or password is incorrect");
//         }

//         setToken(res.data.data.token);
//         setLocalStorageToken(res.data.data.token);
//         setProfileImg(res.data.data.user.profile_photo);
//         setLoginOpen(false);
//       })
//       .catch((e) => {
//         setNotify({
//           isOpen: true,
//           message: e.message,
//           type: "error",
//         });
//       });
//   }

//   return (
//     <Modal
//       dialogClassName={`modal-container  ${styles.modalDialog}`}
//       id="loginModal"
//       size="sm"
//       data-backdrop="false"
//       aria-labelledby="loginModalTitle"
//       show={loginOpen}
//       onHide={() => {
//         document
//           .getElementsByTagName("body")
//           ["0"].classList.remove("modal--open");
//         setLoginOpen(false);
//       }}
//       className={`${styles.divLog}`}
//     >
//       <div
//         className={`modal-dialog-centered ${styles.noBefore} `}
//         role="document"
//       >
//         <div className="modal-content">
//           <div className="modal-header align-items-center justify-content-end">
//             <button
//               type="button"
//               className="close"
//               data-dismiss="modal"
//               aria-label="Close"
//               onClick={() => setLoginOpen(false)}
//             >
//               <span aria-hidden="true" className="la la-times" />
//             </button>
//           </div>
//           <div className="modal-body">
//             <form>
//               <div className="form-group">
//                 <h1 className="fs-20 text-black text-center fw-medium lh-18 pb-3">
//                   Login to CarePal Secure
//                 </h1>
//               </div>
//               <div className="btn-box pb-3 d-flex">
//                 <GoogleLogin
//                   clientId="1013220888592-iuo0p16niklk4hfbvphqft2idgjkn69u.apps.googleusercontent.com"
//                   // clientId={`${process.env.REACT_APP_GOOGLE_LOGIN_CLIENTID}`} //dev
//                   // clientId='224761792682-qtk0ag2nofb0dcm8nkack3gktvb1ej4e.apps.googleusercontent.com'
//                   render={(renderProps) => (
//                     <button
//                       type="button"
//                       className={`btn ${styles.googleButtonCenter} ${styles.googleButton} ${styles.btborder}`}
//                       disabled={renderProps.isProcessing}
//                       onClick={renderProps.onClick}
//                     >
//                       {renderProps.isProcessing && (
//                         <span
//                           className="spinner-border spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         />
//                       )}
//                       <img
//                         src={pic}
//                         className="mr-2"
//                         width="25px"
//                         alt="google"
//                       />
//                       Continue with Google
//                     </button>
//                   )}
//                   autoLoad={false}
//                   onFailure={responseGoogle}
//                   onSuccess={onGoogleLogin}
//                 />
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//       {notify.isOpen && <MyAlert notify={notify} setNotify={setNotify} />}
//     </Modal>
//   );
// };

// export default LoginModal;

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import Modal from "react-bootstrap/Modal";
import {
  setProfileImg,
  setToken as setLocalStorageToken,
} from "../../Services/constant/constant";
import MyAlert from "../../Utils/MyAlert";
import AuthContext from "../AuthContext";
import styles from "./styles.module.scss";
import pic from "../../assets/images/google.png";

const LoginModal = function ({ loginOpen, setLoginOpen, setProfile }) {
  const { setToken } = useContext(AuthContext);
  const [user, setUser] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  async function callBack(googleData) {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_APP_URL}/api/v1/auth/callback`, {
        provider: "google",
        token: googleData.access_token,
      })
      .then((res) => {
        if (res.data.error) {
          if (res.data.status === false)
            throw new Error("Email or password is incorrect");
        }

        setToken(res.data?.data?.token);
        setLocalStorageToken(res.data?.data?.token);
        setProfileImg(res.data.data.user.profile_photo);
        setLoginOpen(false);
      })
      .catch((e) => {
        setNotify({
          isOpen: true,
          message: e.message,
          type: "error",
        });
      });
  }

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      callBack(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    // console.log(user);
    if (user.length) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  // log out function to log the user out of google and set the profile array to null

  return (
    <Modal
      dialogClassName={`modal-container  ${styles.modalDialog}`}
      id="loginModal"
      size="sm"
      data-backdrop="false"
      aria-labelledby="loginModalTitle"
      show={loginOpen}
      onHide={() => {
        document
          .getElementsByTagName("body")
          ["0"].classList.remove("modal--open");
        setLoginOpen(false);
      }}
      className={`${styles.divLog}`}
    >
      <div
        className={`modal-dialog-centered ${styles.noBefore} `}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header align-items-center justify-content-end">
            <button
              type="button"
              className="close"
              id={'login-close-modal-btn'}
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setLoginOpen(false)}
            >
              <span aria-hidden="true" className="la la-times" />
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <h1 className="fs-20 text-black text-center fw-medium lh-18 pb-3">
                  Login to CarePal Secure
                </h1>
              </div>
              <div className="btn-box pb-3 d-flex">
                <button
                  type="button"
                  id={'google-login-btn'}
                  className={`btn ${styles.googleButtonCenter} ${styles.googleButton} ${styles.btborder}`}
                  onClick={() => {
                    login();
                  }}
                >
                  <img src={pic} className="mr-2" width="25px" alt="google" />
                  Continue with Google
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {notify.isOpen && <MyAlert notify={notify} setNotify={setNotify} />}
    </Modal>
  );
};

export default LoginModal;
