import React from "react";
import pic from "./NotFound.png";
import Button from "@mui/material/Button";
import "./NotFound.scss";
import { useNavigate } from "react-router-dom";
const NoMatch = () => {

  const navigate=useNavigate()
  return (
    <>
      <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <img src={pic} style={{width:"100%"}} />
          </div>
        </div>
      </div>
      <div
        className="container-fluid d-flex justify-content-center align-items-center lower-block"
        style={{ height: "50vh" }}
      >
        <div className="row " >
          <div className="col-lg-12 col-sm-12 text-center">
            <h3>Page not found</h3><br/>
            <h5 className="not-found-text">We can’t seem to find the page you are looking for.</h5>
            <h5> Try going back to the previous page.</h5><br/>
            <Button variant="contained" onClick={()=>{navigate("/")}}>Back To Home</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoMatch;
