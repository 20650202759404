import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const MyAlert = function ({ notify, setNotify }) {
  const handleClose = (event, reason) => {

    if (reason === 'clickaway') {
      return;
    }

    setNotify({
      ...notify,
      isOpen: false,
      message: ''
    });
  };

  return (
    <div className='snackbar'>
          <Snackbar
            open={notify.isOpen}
            autoHideDuration={1000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleClose}
          >
            <Alert severity={notify.type} onClose={handleClose} variant='filled'>
              {notify.message}
            </Alert>
          </Snackbar>
    </div>
    
  );
};

export default MyAlert;
