import { React } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Logo from '../../../assets/images/PledgeProtection/header-logo.png';
import '../header.css';

const PledgeHeader = () => {
    return (
        <>
            <section id="header">
                {["lg"].map((expand) => (
                    <Navbar
                        key={expand}
                        bg="light"
                        expand={expand}
                        sticky="top"
                        className="shadow Navbar-fix"
                        style={{ zIndex: 6 }}
                    >
                        <Container className="container" fluid>
                            <Navbar.Brand href="/">
                                <img src={Logo} className="pledge-protection-logo py-2" />
                            </Navbar.Brand>
                        </Container>
                    </Navbar>
                ))}

            </section>
        </>
    );
};

export default PledgeHeader;