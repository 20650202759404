import axiosInstance from "../../Component/AxiosConfig";
import { GoogleLogout } from "../dataServices/dataServices";

export const setToken = (token) => localStorage.setItem("token", token);

export const getToken = () => localStorage.getItem("token");

export const removeToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("img");
};

export const logoutApi = (token) => {
  // GoogleLogout(getToken());
  removeToken();
};

export const pagereload = () => {
  window.location.reload(false);
};
export const setProfileImg = (img) => localStorage.setItem("img", img);

export const getProfileImg = () => localStorage.getItem("img");

export const loginGoogle = async (googleData) => {
  try {
    // console.log(googleData.tokenId, JSON.stringify(googleData.tokenId));
    const res = await axiosInstance.post("/api/v1/login/google/callback", {
      token: JSON.stringify(googleData.tokenId),
    });
    return res;
  } catch (err) {
    // console.error(err.response);
    if (err.response.status === 401) throw new Error("Login Again!!!");
    throw new Error("Something went wrong!!!");
  }
};

export const loginAccount = async (data) => {
  try {
    const res = await axiosInstance.post("/api/v1/login", data);
    return res;
  } catch (err) {
    // console.error(err.response);
    throw new Error("Something went wrong!!!");
  }
};

export const registerAccount = async (data) => {
  try {
    const res = await axiosInstance.post("/api/v1/register", data);
    return res;
  } catch (err) {
    // console.error(err.response);
    throw new Error("Something went wrong!!!");
  }
};

export const fetchProfile = async (token) => {
  const headers = { headers: { Authorization: `Bearer ${token}` } };
  // console.log(headers);
  try {
    const res = await axiosInstance.get("/api/v1/get/profile", headers);
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const getTags = async () => {
  try {
    const res = await axiosInstance.get("/api/v1/tags/list");
    return res;
  } catch (err) {
    if (err.response.status === 401) throw new Error("Login Again!!!");
    throw new Error("Something went wrong!!!");
  }
};

export const contactUs = (data) => {
  try {
    const res = axiosInstance.post("/api/v1/contectus/submit", data);
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const getQuestionsList = async (text, orderBy, page) => {
  try {
    const res = await axiosInstance.get(`/api/v1/forum/search`, {
      params: {
        text,
        order_by: orderBy,
        page,
      },
    });
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const getQuestionsListByCategory = async (
  text,
  categoryId,
  orderBy,
  page
) => {
  try {
    const res = await axiosInstance.get(`/api/v1/forum/search`, {
      params: {
        text,
        category_id: categoryId,
        order_by: orderBy,
        page,
      },
    });
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const getResetOtp = async (email) => {
  try {
    const res = await axiosInstance.get(`/api/v1/reset?email=${email}`);
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const getForum = async (id) => {
  try {
    const res = await axiosInstance.get(`/api/v1/forum/get?forum_id=${id}`);
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const getStats = async () => {
  try {
    const res = await axiosInstance.get("/api/v1/dashboard");
    return res;
  } catch (err) {
    // console.error(err);
    throw new Error("Something went wrong!!!");
  }
};

export const createForum = async (data, token) => {
  try {
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const res = await axiosInstance.post("/api/v1/forum/create", data, headers);
    return res;
  } catch (err) {
    // console.error(err);
    if (err.response.status === 401) throw new Error("Login Again!!!");
    throw new Error("Something went wrong!!!");
  }
};

export const updateProfile = async (data, token) => {
  try {
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const res = await axiosInstance.post(
      "/api/v1/profile/update",
      data,
      headers
    );
    return res;
  } catch (err) {
    // console.error(err);
    if (err.response.status === 401) throw new Error("Login Again!!!");
    throw new Error("Something went wrong!!!");
  }
};

export const loginFacebook = async () => {
  try {
    // console.log('here');
    const res = await axiosInstance.get("/api/v1/login/facebook/callback");
    return res;
  } catch (err) {
    // console.error(err.response);
    if (err.response.status === 401) throw new Error("Login Again!!!");
    throw new Error("Something went wrong!!!");
  }
};

export const getFaq = async (page) => {
  try {
    // console.log('here');
    const res = await axiosInstance.get(`/api/v1/faq?page=${page}`);
    return res;
  } catch (err) {
    // console.error(err.response);
    throw new Error("Something went wrong!!!");
  }
};
