import "./App.css";
import { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import AuthContext from "./Component/AuthContext";
import PledgeHeader from "./Component/Header/PledgeHeader/PledgeHeader";
import LoginModal from "./Component/LoginModel";
import NoMatch from "./Component/NoMatch";
import "../src/assets/sass/style.scss";
// Lazy loading components
const Home = lazy(() => import("./Pages/Home/Home"));
const Community = lazy(() => import("./Pages/Community"));
const QuestionDetails = lazy(() =>
  import("./Pages/QuestionDetails/QuestionDetails")
);
const AskQuestionz = lazy(() => import("./Pages/AskQuestions/index"));
const Header = lazy(() => import("./Component/Header/Header"));
const Privacy = lazy(() => import("./Component/Privacy"));
const Terms = lazy(() => import("./Component/Terms"));
const PledgeProtection = lazy(() =>
  import("./Component/PledgeProtection/PledgeProtection")
);
const Footer = lazy(() => import("./Component/Footer/Footer"));
const HealthInsurance = lazy(() => import("./Pages/HealthInsurance/HealthInsurance"));
const AboutUs=lazy(()=>import("./Pages/About-Us/About-us"))
const CampaignerAdvocate= lazy(() => import("../src/Pages/Campaigner-advocate-experiment/index"));
/*
policy analyzer
const Dashboard = lazy(() => import("./Component/Dashboard"));
const Policy = lazy(() => import("./Component/Policy/Policy"));
const MyProfile = lazy(() => import("./Component/Profile"));
const EditProfile = lazy(() => import("./Component/Profile/EditeProfile"));
const ChangePassword = lazy(() => import("./Component/Profile/ChangePassword"));
const PolicyView = lazy(() => import("./Component/PolicyView"));
const UserProfile = lazy(() => import("./Pages/UserProfile"));
*/
function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loginOpen, setLoginOpen] = useState(false);
  const [newquestion, setNewquestion] = useState([]);
  const [profilePic, setprofilePic] = useState(false);
  let pathName = window.location.pathname.substring(1);
  let isPledgeOrAdvocatePage = pathName === "protection-pledge" || pathName === "referral_benefits";
  
  
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      setToken(null);
    }
  }, []);
  
 
  return (
    <BrowserRouter>
      <AuthContext.Provider
        value={{ token, setToken, setNewquestion, setprofilePic, profilePic }}
      >
        <Suspense
          fallback={<div className="loading-indicator">Loading....</div>}
        >
          {isPledgeOrAdvocatePage   ? <PledgeHeader /> : <Header />}

          <LoginModal loginOpen={loginOpen} setLoginOpen={setLoginOpen} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/protection-pledge" element={<PledgeProtection />} />
            <Route path="/community" element={<Community />} />
            <Route path="/ask-questions" element={<AskQuestionz />} />
            <Route path="/question-details/:id" element={<QuestionDetails />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-of-use" element={<Terms />} />
            <Route path="*" element={<NoMatch />} />
            <Route path="/health-insurance" element={<HealthInsurance/>} />
            <Route path="/referral_benefits" element={<CampaignerAdvocate/>}/>
            <Route path="/success" element={<Home />} />
            <Route path="/failure" element={<Home />} />
            {/* <Route path="/policy" element={ <Protected token={token}> <Policy /></Protected>}/> */}
            {/* <Route path="/profile" element={<Protected token={token}> <MyProfile /></Protected>}/> */}
            {/* <Route path={"/knowyourPolicy"}element={<Protected token={token}> <Dashboard /></Protected>} /> */}
            {/* <Route path="/carepal-secure/edit-profile" element={<Protected token={token}> <EditProfile /></Protected>}
            /> */}
            {/* <Route path="/carepal-secure/change-password" element={<Protected token={token}><ChangePassword /></Protected>}/> */}
            {/* <Route path="/carepal-secure/policy-details/:id" element={<Protected token={token}> <PolicyView /></Protected>}/> */}
            {/* <Route path="/user-profile" element={<Protected token={token}> <UserProfile /> </Protected> }/> */}
            {/* <Route path="/settings" element={<Settings />} /> */}
          </Routes>
          {!isPledgeOrAdvocatePage && <Footer />}
        </Suspense>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
