import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/line-awesome.css";
import "../src/assets/css/style.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider
    clientId={`${process.env.REACT_APP_GOOGLE_LOGIN_CLIENTID}`}
  >
    <App />
  </GoogleOAuthProvider>
);
