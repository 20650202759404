import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_APP_URL
 
});

axiosInstance.interceptors.request.use((request) => {
  return request;
});

export default axiosInstance;
